<template>
  <div class="ventasstreaming">
    <tabla titulo="Ventas Billetera" 
          :headers = "headers"
          :arreglo = "VentasGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/tramites/billetera/index-ventas';

export default {
  name: 'ventasstreaming',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarVentas()
  },

  data: () =>{
    return{
    headers: [
        { text: 'ID', align: 'start', value: 'id', sortable: true },
        { text: 'PUNTO DE VENTA', value: 'razon_social', sortable: true },
        { text: 'ROT', value: 'rot', sortable: true },
        { text: 'ID ROT', value: 'id_transaccion', sortable: true },
        { text: 'VALOR', value: 'valor_format', sortable: true },
        { text: 'COMISIÓN', value: 'comision_format', sortable: true },
        { text: 'DISPONIBLE ANTERIOR', value: 'disponible_anterior', sortable: true },
        { text: 'DISPONIBLE ACTUAL', value: 'disponible_actual', sortable: true },
        { text: 'FECHA COMPRA', value: 'fecha_compra_format', sortable: true },
        { text: 'USUARIO', value: 'nombre_usuario', sortable: true },
        { text: 'CUENTA', value: 'cuenta', sortable: true },
        { text: 'CHEQUEADO', value: 'chequeado', sortable: true },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions('billeteras',['ConsultarVentas']),

  },

  computed:{
      ...mapGetters('billeteras',['VentasGetter'])
  }
}
</script>

<template>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID VENTA: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.rot"
                  label="Rot"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.id_transaccion"
                  label="ID Rot"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.razon_social"
                  label="Punto de Venta"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.nombre_usuario"
                  label="Usuario"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.valor_format"
                  label="Valor"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.comision_format"
                  label="Comisión"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.disponible_anterior"
                  label="Disponible Anterior"
                  readonly
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.disponible_actual"
                  label="Disponible Actual"
                  readonly
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.fecha_compra_format"
                  label="Fecha Compra"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  v-model="Item.chequeado"
                  label="Chequeado"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  rows="1"
                  counter
                  label="Notas"
                  v-model="Item.notas"
                  readonly
                ></v-textarea>
              </v-col>

            </v-row>
          </v-container>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
  },

  data: () => ({
    var: null,
    DialogAceptar: false,
  }),

  methods: {
     ...mapActions([]),

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['DatosdeUsuario'])
  }


})
</script>
